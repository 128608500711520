import { FunctionComponent, useEffect, useState } from "react";
import {
  CartesianGrid,
  Legend,
  LineChart as Chart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
  Line,
} from "recharts";
import { TypeElement } from "../../../shared/enums/typeContent/typeElement.enum";
import { useInstrumentsService } from "../../../hooks/api/instruments";
import { useGroupsService } from "../../../hooks/api/groups";
import { useTemperatureService } from "../../../hooks/api/temperature";
import CheckboxPoint from "../../checkbox-points";
import { CheckboxContainer, InputContainer, InputField } from "./styles";
import { statusTermopar } from "../../../shared/enums/termopar/statusTermopar.enum";
import { FullScreen } from "react-full-screen";
import { lineColors } from "../../../shared/line-colors/lineColors";
import { loadChartData, saveChartData } from "../../../utils/chartsStorage";

interface CustomAreaChartProps {
  id: number;
  type: TypeElement;
  dataTemperatures?: number[];
  fullScreen?: boolean;
}

interface IDataGraph {
  dateTime: string;
  termopares: {
    tag: string;
    temperature: number;
  }[];
}

interface IDataTermopar {
  id: number;
  tag: string;
  checked: boolean;
}

const CustomAreaChart: FunctionComponent<CustomAreaChartProps> = ({
  id,
  type,
  dataTemperatures = [],
  fullScreen = false,
}) => {
  const { getOneInstrument } = useInstrumentsService();
  const { getOneGroup } = useGroupsService();
  const { getTemperatureCurrentByGroup, getTemperatureCurrentByInstrument } =
    useTemperatureService();

  const [dataGraph, setDataGraph] = useState<IDataGraph[]>([]);
  const [dataTermopares, setDataTermopares] = useState<IDataTermopar[]>([]);
  const [showTooltip, setShowTooltip] = useState<boolean>(true);
  const [isRequest, setIsRequest] = useState<boolean>(false);
  const [filteredData, setFilteredData] = useState<IDataGraph[]>([]);
  const [minY, setMinY] = useState<number | undefined>(undefined);
  const [maxY, setMaxY] = useState<number | undefined>(undefined);

  async function getDataInstrument() {
    const dataTermopar: IDataTermopar[] = [];
    try {
      const { data } = await getOneInstrument(id);
      data.termopares = data.termopares.filter(
        (termopar) => termopar.status !== statusTermopar.DESABILITADO
      );
      data.termopares.forEach((termopar) => {
        dataTermopar.push({
          id: termopar.id,
          tag: termopar.tag,
          checked: true,
        });
      });
      setDataTermopares(dataTermopar);
    } finally {
      setIsRequest(false);
    }
  }

  async function getDataGrupos() {
    const dataTermopar: IDataTermopar[] = [];
    try {
      const { data } = await getOneGroup(id);
      data.termopares = data.termopares.filter(
        (termopar) => termopar.status !== statusTermopar.DESABILITADO
      );
      data.termopares.forEach((termopar) => {
        dataTermopar.push({
          id: termopar.id,
          tag: termopar.tag,
          checked: true,
        });
      });
      setDataTermopares(dataTermopar);
    } finally {
      setIsRequest(false);
    }
  }

  async function getTemperature() {
    setIsRequest(true);
    let newDataGraph: IDataGraph = {
      dateTime: "",
      termopares: [],
    };
    let temperatures: number[] = [];

    let date = new Date();
    let formattedTime = date.toLocaleTimeString("pt-BR", {
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
    });

    // Pegar as temperaturas atuais
    if (!fullScreen) {
      temperatures = dataTemperatures;
    } else {
      const { data } =
        type === TypeElement.INSTRUMENTO
          ? await getTemperatureCurrentByInstrument(id)
          : await getTemperatureCurrentByGroup(id);
      temperatures = data;
    }

    // Atualiza o newDataGraph com o horário atual
    newDataGraph.dateTime = formattedTime;
    console.log(dataTemperatures);
    console.log(dataTermopares);
    // Adiciona os termopares e sua temperatura no newDataGraph
    for (let index = 0; index < dataTermopares.length; index++) {
      if (temperatures[index] !== undefined) {
        newDataGraph.termopares.push({
          tag: dataTermopares[index].tag,
          temperature: temperatures[index],
        });
      }
    }

    // Atualiza o estado com novos dados
    setDataGraph((prevData) => {
      let data = [...prevData, newDataGraph];
      if (data.length >= 5) {
        data.shift();
      }
      return data;
    });

    setIsRequest(false);
  }

  function filterTemperaturaBySelectTags() {
    return dataGraph.map((entry) => {
      const filteredTermopares = entry.termopares.filter((termopar) =>
        dataTermopares.find((tag) => tag.tag === termopar.tag && tag.checked)
      );
      return {
        ...entry,
        termopares: filteredTermopares,
      };
    });
  }

  function handleCheckboxChange(index: number) {
    setDataTermopares((prevTags) => {
      const newTags = [...prevTags];
      newTags[index].checked = !newTags[index].checked;
      return newTags;
    });
  }

  function formatDataForGraph(data: IDataGraph[]) {
    return data.map((entry) => {
      const formattedEntry: { [key: string]: number | string } = {
        name: entry.dateTime,
      };

      entry.termopares.forEach((termopar) => {
        formattedEntry[termopar.tag] = termopar.temperature;
      });

      return formattedEntry;
    });
  }

  useEffect(() => {
    const filtered = filterTemperaturaBySelectTags();
    setFilteredData(filtered);
  }, [dataGraph, dataTermopares]);

  useEffect(() => {
    if (!isRequest) {
      setIsRequest(true);
      if (type === TypeElement.INSTRUMENTO) {
        getDataInstrument();
      } else {
        getDataGrupos();
      }
    }
  }, [id, type]);

  useEffect(() => {
    if (!isRequest && dataTermopares.length > 0) {
      setIsRequest(true);
      getTemperature();
    }
  }, [dataTermopares, setDataTermopares]);

  useEffect(() => {
    if (
      !isRequest &&
      dataTermopares.length > 0 &&
      dataTemperatures.length > 0
    ) {
      setIsRequest(true);
      getTemperature();
    }
  }, [dataTemperatures]);

  useEffect(() => {
    if (minY !== undefined) {
      localStorage.setItem("minY-areaChart", minY.toString());
    }
    if (maxY !== undefined) {
      localStorage.setItem("maxY-areaChart", maxY.toString());
    }
  }, [minY, maxY, setMinY, setMaxY]);

  useEffect(() => {
    if (fullScreen) {
      const intervalTime = Number(process.env.TIME_SET_INTERVAL_CHARTS) || 5000;
      const interval = setInterval(() => {
        if (!isRequest && dataTermopares.length > 0) {
          setIsRequest(true);
          getTemperature();
          console.log("Buscando Temperatura");
        }
      }, intervalTime);
      return () => clearInterval(interval);
    }
  }, [fullScreen, dataTermopares]);

  // Carrega os dados salvos ao montar o componente
  useEffect(() => {
    const savedData = loadChartData(id, "areaChart");
    if (savedData) {
      setMinY(savedData.minY);
      setMaxY(savedData.maxY);
    }
  }, [id]);

  // Salva os dados sempre que minY ou maxY mudar
  useEffect(() => {
    saveChartData(id, "areaChart", { minY, maxY });
  }, [minY, maxY, id]);

  return (
    <>
      <InputContainer>
        <label>
          Valor Mínimo Y:
          <InputField
            type="number"
            value={minY ?? ""}
            onChange={(e: any) => setMinY(Number(e.target.value))}
          />
        </label>
        <label>
          Valor Máximo Y:
          <InputField
            type="number"
            value={maxY ?? ""}
            onChange={(e: any) => setMaxY(Number(e.target.value))}
          />
        </label>
      </InputContainer>

      <CheckboxPoint
        text="Mostrar Informações"
        action={() => setShowTooltip(!showTooltip)}
        checked={showTooltip}
      />

      <div style={{ height: "750px", width: "100%" }}>
        {filteredData.length > 0 && (
          <ResponsiveContainer>
            <Chart
              width={700}
              height={200}
              data={formatDataForGraph(filteredData)}
              margin={{ top: 5, bottom: 5 }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis
                domain={[
                  minY
                    ? (maxDataValue: number) =>
                        minY <= maxDataValue
                          ? minY
                          : Math.max(minY, maxDataValue)
                    : 0,
                  maxY
                    ? (maxDataValue: number) =>
                        maxY >= maxDataValue
                          ? maxY
                          : Math.min(maxY, maxDataValue)
                    : "",
                ]}
                allowDataOverflow={true}
              />
              <Tooltip />
              <Legend />
              {dataTermopares.map(
                (termopar, index) =>
                  termopar.checked && (
                    <Line
                      key={termopar.tag}
                      type="monotone"
                      dataKey={termopar.tag}
                      stroke={
                        lineColors[
                          `line${index + 1}` as keyof typeof lineColors
                        ]
                      }
                      fill={
                        lineColors[
                          `line${index + 1}` as keyof typeof lineColors
                        ]
                      }
                      dot={false}
                      strokeWidth={2}
                    />
                  )
              )}
            </Chart>
          </ResponsiveContainer>
        )}
      </div>
      <CheckboxContainer>
        {dataTermopares.map((termopar, index) => (
          <CheckboxPoint
            key={index}
            text={termopar.tag}
            action={() => handleCheckboxChange(index)}
            checked={termopar.checked}
          />
        ))}
      </CheckboxContainer>
    </>
  );
};

export default CustomAreaChart;
