import { ChartData } from "../shared/interfaces/charts/charts.local.storage";

export const saveChartData = (
  id: number,
  chartType: string,
  data: ChartData
) => {
  const storedData = localStorage.getItem(`graph${id}`);
  const parsedData = storedData ? JSON.parse(storedData) : {};
  parsedData[`${chartType}`] = data; // Salva os dados para o tipo de gráfico específico
  localStorage.setItem(`graph${id}`, JSON.stringify(parsedData));
};

export const loadChartData = (
  id: number,
  chartType: string
): ChartData | undefined => {
  const storedData = localStorage.getItem(`graph${id}`);
  if (storedData) {
    const parsedData = JSON.parse(storedData);
    return parsedData[`${chartType}`]; // Retorna os dados para o tipo de gráfico específico
  }
  return undefined;
};
