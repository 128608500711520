import { FunctionComponent, useCallback, useEffect, useState } from "react";
import { Bar, BarChart, YAxis } from "recharts";
import { Col, Container, Row } from "react-bootstrap";
import {
  BoxForStatusIcon,
  PointBox,
  PointLabel,
  PointTemperature,
} from "../../instrument-container/styles";
import { IoAlertCircleOutline } from "react-icons/io5";
import { FaRegSnowflake } from "react-icons/fa";
import { SiFireship } from "react-icons/si";
import { useInstrumentsService } from "../../../hooks/api/instruments";
import { useGroupsService } from "../../../hooks/api/groups";
import { TypeElement } from "../../../shared/enums/typeContent/typeElement.enum";
import { statusTermopar } from "../../../shared/enums/termopar/statusTermopar.enum";
import { EPointStatus } from "../../../pages/dashboard/enum/points.enum";
import { CheckboxContainer, InputContainer, InputField } from "./styles";
import CheckboxPoint from "../../checkbox-points";
import { useTemperatureService } from "../../../hooks/api/temperature";
import { loadChartData, saveChartData } from "../../../utils/chartsStorage";

interface TemperatureChartProps {
  id: number;
  type: TypeElement;
  dataTemperature?: number[];
  fullScreen?: boolean;
}

interface IDataTemperature {
  id: number;
  tag: string;
  temperature: number;
  status: EPointStatus;
  color: string;
  min: number;
  max: number;
}

const TemperatureChart: FunctionComponent<TemperatureChartProps> = ({
  id,
  type,
  dataTemperature = [],
  fullScreen = false,
}) => {
  const { getOneInstrument } = useInstrumentsService();
  const { getOneGroup } = useGroupsService();
  const { getLastTemperatureByTermopares } = useTemperatureService();

  const [dataGraph, setDataGraph] = useState<IDataTemperature[]>([]);
  const [dataTagsTermopares, setTagsTermopares] = useState<string[]>([]);
  const [selectedTags, setSelectedTags] = useState<Set<string>>(new Set());
  const [idTermopares, setIdTermopares] = useState<number[]>([]);

  const [isRequest, setIsRequest] = useState<boolean>(false);

  const [minY, setMinY] = useState<number | undefined>(undefined);
  const [maxY, setMaxY] = useState<number | undefined>(undefined);

  const getDataInstrument = async () => {
    if (id) {
      await getOneInstrument(id)
        .then(({ data }) => {
          data.termopares = data.termopares.filter(
            (termopar) => termopar.status !== statusTermopar.DESABILITADO
          );
          const termoparesData = data.termopares.map((termopar) => {
            const temperatura =
              termopar.temperatura && termopar.temperatura.length > 0
                ? termopar.temperatura[0].valor
                : null;

            let Status;
            let Color;
            if (temperatura !== null) {
              if (termopar.status === statusTermopar.INOPERANTE) {
                Status = EPointStatus.ALERT;
                Color = "#d87d0d";
              } else if (temperatura! < termopar.minima) {
                Status = EPointStatus.COLD;
                Color = "#3B82F6";
              } else if (temperatura! > termopar.maxima) {
                Status = EPointStatus.HOT;
                Color = "#B91C1C";
              } else {
                Status = EPointStatus.OK;
                Color = "#c0c0c0";
              }
            } else {
              Status = EPointStatus.OK;
              Color = "#c0c0c0";
            }
            return {
              id: termopar.id,
              tag: termopar.tag,
              temperature: temperatura !== null ? temperatura : 0,
              status: Status,
              color: Color,
              min: termopar.minima,
              max: termopar.maxima,
            };
          });

          setDataGraph(termoparesData);
          const tags: string[] = [];
          const idTermopares: number[] = [];
          data.termopares.map((termopar) => {
            tags.push(termopar.tag);
            idTermopares.push(termopar.id);
          });
          setIdTermopares(idTermopares);
          setTagsTermopares(tags);
          setSelectedTags(new Set(tags));
        })
        .finally(() => {
          setIsRequest(false);
        });
    }
  };

  const getDataGrupos = async () => {
    if (id) {
      await getOneGroup(id)
        .then(async ({ data }) => {
          data.termopares = data.termopares.filter(
            (termopar) => termopar.status !== statusTermopar.DESABILITADO
          );
          let Status;
          let Color;
          const termoparesData = data.termopares.map((termopar) => {
            if (termopar.status === statusTermopar.INOPERANTE) {
              Status = EPointStatus.ALERT;
              Color = "#d87d0d";
            } else {
              Status = EPointStatus.OK;
              Color = "#FFF";
            }
            return {
              id: termopar.id,
              tag: termopar.tag,
              temperature: 0,
              status: Status,
              color: Color,
              min: termopar.minima,
              max: termopar.maxima,
            };
          });

          setDataGraph(termoparesData);
          const tags: string[] = [];
          const idTermopares: number[] = [];
          data.termopares.map((termopar) => {
            tags.push(termopar.tag);
            idTermopares.push(termopar.id);
          });
          setIdTermopares(idTermopares);
          setTagsTermopares(tags);
          setSelectedTags(new Set(tags));
        })
        .finally(() => {
          setIsRequest(false);
        });
    }
  };

  const getTemperature = async () => {
    let temperaturas: number[] = [];
    if (!fullScreen) {
      temperaturas = dataTemperature;
    } else {
      let { data } = await getLastTemperatureByTermopares(idTermopares);
      console.log(data);
      for (let termopar of data) {
        temperaturas.push(termopar.temperature.value);
      }
    }

    for (let index = 0; index < dataGraph.length; index++) {
      let termopar = dataGraph[index];
      const temperatura = temperaturas[index];
      let Status;
      let Color;

      if (temperatura !== null) {
        if (temperatura < termopar.min) {
          Status = EPointStatus.COLD;
          Color = "#3B82F6";
        } else if (temperatura > termopar.max) {
          Status = EPointStatus.HOT;
          Color = "#B91C1C";
        } else {
          Status = EPointStatus.OK;
          Color = "#c0c0c0";
        }
      } else {
        Status = EPointStatus.OK;
        Color = "#c0c0c0";
      }

      dataGraph[index].temperature = temperatura;
      dataGraph[index].status = Status;
      dataGraph[index].color = Color;
    }
    setIsRequest(false);
  };

  const filterTagsName = (tag: string) => {
    const newSelectedTags = new Set(selectedTags);
    if (newSelectedTags.has(tag)) {
      newSelectedTags.delete(tag);
    } else {
      newSelectedTags.add(tag);
    }
    setSelectedTags(newSelectedTags);
  };

  const filterTemperaturaBySelectTags = (
    data: IDataTemperature[],
    tags: Set<string>
  ) => {
    return data.filter((entry) => tags.has(entry.tag));
  };

  const handleCheckboxChange = (tag: string) => {
    filterTagsName(tag);
  };

  const filteredData = filterTemperaturaBySelectTags(dataGraph, selectedTags);

  const checkIcon = useCallback((status: EPointStatus) => {
    switch (status) {
      case "alert":
        return (
          <BoxForStatusIcon>
            <IoAlertCircleOutline color="#DC2626" />
          </BoxForStatusIcon>
        );
      case "cold":
        return (
          <BoxForStatusIcon>
            <FaRegSnowflake color="#3B82F6" />
          </BoxForStatusIcon>
        );
      case "hot":
        return (
          <BoxForStatusIcon>
            <SiFireship color="#B91C1C " />
          </BoxForStatusIcon>
        );
      case "ok":
        return <></>;
      default:
        return <></>;
    }
  }, []);

  useEffect(() => {
    if (!isRequest) {
      setDataGraph([]);
      setIsRequest(true);
      if (type === TypeElement.INSTRUMENTO) {
        getDataInstrument();
      } else {
        getDataGrupos();
      }
    }
  }, [id, type]);

  useEffect(() => {
    if (!isRequest && dataTemperature.length > 0) {
      setIsRequest(true);
      getTemperature();
    }
  }, [dataTemperature]);

  useEffect(() => {
    if (!isRequest && idTermopares.length > 0) {
      setIsRequest(true);
      getTemperature();
    }
  }, [idTermopares, setIdTermopares]);

  useEffect(() => {
    console.log(
      Number(process.env.TIME_SET_INTERVAL_CHARTS),
      " - ",
      process.env.TIME_SET_INTERVAL_CHARTS
    );
    console.log(fullScreen);
    if (fullScreen) {
      const intervalTime =
        Number(process.env.TIME_SET_INTERVAL_CHARTS) || 30000;
      const interval = setInterval(() => {
        if (!isRequest) {
          setIsRequest(true);
          getTemperature();
          console.log("Buscando Temperatura");
        }
      }, intervalTime);
      return () => clearInterval(interval);
    }
  }, [fullScreen]);

  useEffect(() => {
    const savedData = loadChartData(id, "tempChart");
    if (savedData) {
      setMinY(savedData.minY);
      setMaxY(savedData.maxY);
    }
  }, [id]);

  useEffect(() => {
    saveChartData(id, "tempChart", { minY, maxY });
  }, [minY, maxY, id]);

  return (
    <>
      <InputContainer>
        <label>
          Valor Mínimo Y:
          <InputField
            type="number"
            value={minY ?? ""}
            onChange={(e: any) => setMinY(Number(e.target.value))}
          />
        </label>
        <label>
          Valor Máximo Y:
          <InputField
            type="number"
            value={maxY ?? ""}
            onChange={(e: any) => setMaxY(Number(e.target.value))}
          />
        </label>
      </InputContainer>
      <Container
        className="d-flex align-items-center justify-content-center"
        style={{ height: "auto" }}
      >
        <Row>
          {filteredData.map((item, index) => {
            let formatedData = [item];
            return (
              <Col
                key={index}
                md={3}
                className="d-flex align-items-start justify-content-center mt-2"
              >
                <BarChart width={180} height={180} data={formatedData}>
                  <YAxis
                    label=""
                    domain={[
                      minY
                        ? (maxDataValue: number) =>
                            minY <= maxDataValue
                              ? minY
                              : Math.max(minY, maxDataValue)
                        : 0,
                      maxY
                        ? (maxDataValue: number) =>
                            maxY >= maxDataValue
                              ? maxY
                              : Math.min(maxY, maxDataValue)
                        : "",
                    ]}
                    allowDataOverflow={true}
                  />
                  <Bar
                    dataKey="temperature"
                    fill={item.color}
                    radius={[5, 5, 5, 10]}
                  />
                </BarChart>
                <PointBox>
                  <PointTemperature status={item.status as EPointStatus}>
                    {item.temperature > 0 ? `${item.temperature}°C` : "N/A"}
                  </PointTemperature>
                  {checkIcon(item.status as EPointStatus)}
                  <PointLabel status={item.status as EPointStatus}>
                    {item.tag}
                  </PointLabel>
                </PointBox>
              </Col>
            );
          })}
        </Row>
      </Container>
      <CheckboxContainer>
        {dataTagsTermopares.map((tag, index) => (
          <CheckboxPoint
            key={index}
            text={tag}
            action={() => handleCheckboxChange(tag)}
            checked={selectedTags.has(tag)}
          />
        ))}
      </CheckboxContainer>
    </>
  );
};

export default TemperatureChart;
